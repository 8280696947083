import React, { Component }  from "react";
import { Fade, Slide } from "react-reveal";

class Contact extends Component {
  render() {
    if (!this.props.data) return null;

    const name = this.props.data.name;
    const street = this.props.data.address.street;
    const city = this.props.data.address.city;
    const state = this.props.data.address.state;
    const zip = this.props.data.address.zip;
    const phone = this.props.data.phone;
    const message = this.props.data.contactmessage;

  
    


   

    return (
      <section id="contact">
      

        <div className="row">
    

          <Slide right duration={1000}>
            <aside className="four columns footer-widgets">
              
            <div className="widget widget_contact">
                <h2>Adresse et téléphone</h2>
                <p className="address" style={{fontSize:"25px"}}>
                  {name}
                  <br />
                  {street} <br />
                  {city}, {state} {zip}
                  <br />
                  <span>{phone}</span>
                </p>
              </div>
            
            </aside>
          </Slide>
        </div>
      </section>
    );
  }
}

export default Contact;
